var initJS = {
  moreContent: function() {
    if ("querySelector" in document && "addEventListener" in window) {
      let fullTextWrappers = document.querySelectorAll(
        ".card--trimmed .card-text"
      )

      ;[].forEach.call(fullTextWrappers, function(fullTextWrapper) {
        let fullTextContent = fullTextWrapper.innerHTML;
        fullTextContent.replace(/<p>​<\/p>/gi, '')
        fullTextWrapper.innerHTML  = fullTextContent.toString();

        let toggleButton = fullTextWrapper.parentNode.querySelector(
          ".toggle-content"
        )

          if (fullTextWrapper.scrollHeight > fullTextWrapper.clientHeight) {
          toggleButton.style.display = "block"
        }

        toggleButton.addEventListener("click", function() {
          if (fullTextWrapper.classList) {
            fullTextWrapper.classList.toggle("short-text")

            fullTextWrapper.parentNode.parentNode.classList.toggle(
              "card--expanded"
            )

            let showMore = toggleButton.querySelector(".show-more")
            let showLess = toggleButton.querySelector(".show-less")

            if (!fullTextWrapper.classList.contains("short-text")) {
              showMore.style.display = "none"
              showLess.style.display = "block"
            } else {
              showMore.style.display = "block"
              showLess.style.display = "none"
            }
          } else {
            // For IE9
            let classes = element.className.split(" ")
            let i = classes.indexOf("short-text")

            if (i >= 0) classes.splice(i, 1)
            else classes.push("short-text")
            element.className = classes.join(" ")
          }
        })
      })
    }
  },

  init: function() {
    this.moreContent()
  },
}

window.onload = initJS.init()
